import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as d3 from 'd3';

@Component({
  selector: 'codenteam-scan-gauge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scan-gauge.component.html',
  styleUrl: './scan-gauge.component.scss',
})
export class ScanGaugeComponent implements AfterViewInit {
  @ViewChild('gauge', { static: true }) gauge!: ElementRef;
  @Input()
  data: { label: string; value: number; color: string }[];
  @Input()
  percentage: number;
  @Input()
  radius: number;

  public ngAfterViewInit() {
    this.draw();
  }

  private draw() {
    const height = this.radius + 20;
    const width = this.radius * 2.2;
    const centerX = width / 2;
    const centerY = this.radius;
    const needleCircleRadius = this.radius / 6;
    const needleHeight = this.radius * 0.45;

    const canvas = d3
      .select(this.gauge.nativeElement)
      .attr('width', width)
      .attr('height', height);

    const defs = canvas.append('defs');

    defs
      .append('filter')
      .attr('id', 'drop-shadow')
      .attr('x', '-50%')
      .attr('y', '-50%')
      .attr('width', '200%')
      .attr('height', '200%')
      .append('feDropShadow')
      .attr('dx', 3)
      .attr('dy', 3)
      .attr('stdDeviation', 3)
      .attr('flood-color', 'rgba(0,0,0,0.5)');

    const group = canvas
      .append('g')
      .attr('transform', `translate(${centerX},${centerY + 5})`);
    const color = d3
      .scaleOrdinal<string>()
      .domain(this.data.map((d) => d.label)) // Domain based on labels in colorData
      .range(this.data.map((d) => d.color));
    const pie = d3
      .pie<any>()
      .value((d: any) => {
        return d.value;
      })
      .startAngle(-Math.PI / 2)
      .endAngle(Math.PI / 2)
      .padAngle(0.032);
    const arc = d3
      .arc()

      .innerRadius(this.radius - this.radius / 4)
      .outerRadius(this.radius)
      .cornerRadius(this.radius / 13);

    const arcs = group
      .selectAll('.arc')
      .data(pie(this.data))
      .enter()
      .append('g')
      .attr('class', 'arc');

    arcs
      .append('path')
      .attr('d', <any>arc)
      .attr('fill', (d) => color(d.data.label))
      .attr('filter', 'url(#drop-shadow)'); // Apply the shadow filter
    const symbol = canvas
      .append('svg')
      .attr('class', 'custom-symbol')
      .append('symbol')
      .attr('id', 'mySymbol')
      .attr('viewBox', `0 0  ${width} ${height}`);

    symbol
      .append('circle')
      .attr('cx', centerX)
      .attr('cy', centerY)
      .attr('r', needleCircleRadius)
      .style('fill', '#475569');

    const areaPath = d3.line().curve(d3.curveCatmullRom.alpha(0.0))([
      [centerX - needleCircleRadius - 0.2, centerY],
      [centerX, needleHeight],
      [centerX + needleCircleRadius + 0.2, centerY],
    ]);

    symbol.append('path').attr('d', areaPath).style('fill', '#475569');

    symbol
      .append('circle')
      .attr('cx', centerX)
      .attr('cy', centerY)
      .attr('r', needleCircleRadius / 2)
      .style('fill', '#ffffff')
      .style('stroke', '#ffffff');
    canvas
      .append('use')
      .attr('xlink:href', '#mySymbol')
      .attr('transform', `rotate(${this.percentage},${centerX},${centerY})`);
  }
}
